import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {ClientTypeSelector} from '../pages/ordini_noleggio-vendita/Inserisci_ordine/ClientTypeSelector'
//import {InserisciOrdine} from '../pages/ordini_noleggio-vendita/Inserisci_ordine/Inserisci_ordine'
//import {Inserisci_prove_gratuite} from '../pages/prove_gratuite/Inserisci_prova_gratuita/Inserisci_prova_gratuita'
import {InserisciVisoreInStock} from '../pages/ordini_noleggio-vendita/Inserisci_visore_in_stock/Inserisci_visore_in_stock'
import {InserisciPianoAbbonamento} from '../pages/ordini_noleggio-vendita/Inserisci_piano_abbonamento/Inserisci_piano_abbonamento'
import {InserisciIntermediario} from '../pages/ordini_noleggio-vendita/Inserisci_intermediario/Inserisci_intermediario'
import {InserisciProssimaUscita} from '../pages/ordini_noleggio-vendita/Inserisci_prossima_uscita/Inserisci_prossima_uscita'
import {GestioneUtenti} from '../pages/console_admin/Gestione_utenti'
//import {Stato_prove_gratuite} from '../pages/prove_gratuite/Stato_ordini'
import {StatoOrdini} from '../pages/ordini_noleggio-vendita/Stato_ordini'
import {LibroMastro} from '../pages/gestione_visori/Libro_mastro'
//import {Statistiche} from '../pages/ordini_noleggio-vendita/Statistiche'
//import {Magazzino} from '../pages/ordini_noleggio-vendita/Magazzino'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {Login} from '../modules/auth/components/Login'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='login' element={<Login />} />
        <Route path='inserisci-ordine' element={<ClientTypeSelector />} />
        {/*<Route path='inserisci-prove-gratuite' element={<Inserisci_prove_gratuite />} />*/}
        {/*<Route path='stato-prove-gratuite' element={<Stato_prove_gratuite />} />*/}
        <Route path='stato-ordini' element={<StatoOrdini />} />
		    <Route path='inserisci-visore' element={<InserisciVisoreInStock />} />
        <Route path='libro-mastro' element={<LibroMastro />} />
		    <Route path='inserisci-piano-abbonamento' element={<InserisciPianoAbbonamento />} />
		    <Route path='inserisci-intermediario' element={<InserisciIntermediario />} />
        <Route path='inserisci-prossima-uscita' element={<InserisciProssimaUscita />} />
        <Route path='gestione-utenti' element={<GestioneUtenti />} />
        {/*<Route path='statistiche' element={<Statistiche />} />
        <Route path='magazzino' element={<Magazzino />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />*/}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
