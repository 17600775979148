import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useRoles } from '../../../../../hooks/userRoleContext'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { roles } = useRoles()

  // Verifica se l'utente ha il ruolo 'admin'
  const isAdmin = roles ? roles.includes('admin') : false
  console.log(roles)

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      {/* Ordini noleggio/vendita */}
      <SidebarMenuItemWithSub
        to='#'
        title='Ordini'
        icon='/media/icons/duotune/abstract/abs034.svg'
        fontIcon='bi-archive'
      >
        <SidebarMenuItem to='/inserisci-ordine' title='Inserisci ordine' hasBullet={true} />
        <SidebarMenuItem to='/stato-ordini' title='Stato ordini' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Gestione visori */}
      <SidebarMenuItemWithSub
        to='#'
        title='Gestione visori'
        icon='/media/icons/duotune/technology/teh006.svg'
        fontIcon='bi-archive'
      >
        <SidebarMenuItem to='/inserisci-visore' title='Inserisci visore' hasBullet={true} />
        <SidebarMenuItem to='/libro-mastro' title='Libro mastro' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Altri inserimenti */}
      <SidebarMenuItemWithSub
        to='#'
        title='Altri inserimenti'
        icon='/media/icons/duotune/general/gen055.svg'
        fontIcon='bi-archive'
      >
        <SidebarMenuItem to='/inserisci-piano-abbonamento' title='Inserisci piano abbonamento' hasBullet={true} />
        <SidebarMenuItem to='/inserisci-intermediario' title='Inserisci intermediario' hasBullet={true} />
        <SidebarMenuItem to='/inserisci-prossima-uscita' title='Inserisci prossima uscita' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Impostazioni - solo per admin */}
      {isAdmin && (
        <>
          <SidebarMenuItemWithSub
            to='#'
            title='Console Admin'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen051.svg'
          >
            <SidebarMenuItem to='/gestione-utenti' title='Gestione utenti' hasBullet={true} />
          </SidebarMenuItemWithSub>
        </>
      )}
    </>
  )
}

export { SidebarMenuMain }
