/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import Select from 'react-select'
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { listIntermediaries } from '../../../../../api/ordersApi';


const Step1: FC = () => {

  const [isIntermediary, setIsIntermediary] = useState(false);
  const [intermediariesList, setIntermediariesList] = useState<any[]>([]);
  const [selectedIntermediary, setSelectedIntermediary] = useState('');

  const { setFieldValue } = useFormikContext();

  const { values, errors, isValid } = useFormikContext();

  useEffect(() => {
    console.log('Valori del form:', values);
    console.log('Errori di validazione:', errors);
    console.log('Il form è valido?', isValid);
  }, [values, errors, isValid]);

  useEffect(() => {
    const fetchIntermediaries = async () => {
      try {
        const response = await listIntermediaries();
        console.log("resss 2", response);
        setIntermediariesList(response);
      } catch (error) {
        console.error('Errore nel recupero degli intermediari:', error);
      }
    };
    fetchIntermediaries();
  }, []);

  const handleIntermediaresChange = (event:any) => {
    setIsIntermediary(event.target.value === 'Si');
  }

  const handleIntermediaresSelectChange = (option: { value: string } | null) => {
    setSelectedIntermediary(option ? option.value : '');
  };

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Anagrafica cliente</h2>
        <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
      </div>

      {/* Ragione sociale */}
      <div className='fv-row mb-10'>
        <label htmlFor='Ragione_sociale' className='form-label required'>
          Ragione sociale
        </label>
        <Field
          type='text'
          id='Ragione_sociale'
          name='Ragione_sociale'
          className='form-control form-control-lg form-control-solid'
        />
  
          <div className='text-danger mt-2'>
            <ErrorMessage name='Ragione_sociale' />
          </div>
        
      </div>

      {/* Partita IVA */}
      <div className='fv-row mb-10'>
        <label htmlFor='P_IVA' className='form-label required'>
          Partita IVA (non mettere prefisso IT)
        </label>
        <Field
          type='text'
          id='P_IVA'
          name='P_IVA'
          className='form-control form-control-lg form-control-solid'
        />

          <div className='text-danger mt-2'>
            <ErrorMessage name='P_IVA' />
          </div>
        
      </div>

      {/* Codice fiscale */}
      <div className='fv-row mb-10'>
        <label htmlFor='CF' className='form-label required'>
          Codice Fiscale
        </label>
        <Field
          type='text'
          id='CF'
          name='CF'
          className='form-control form-control-lg form-control-solid'
        />

          <div className='text-danger mt-2'>
            <ErrorMessage name='CF' />
          </div>        
        
      </div>

      {/* SDI */}
      <div className='fv-row mb-10'>
        <label htmlFor='SDI' className='form-label required'>
          SDI
        </label>
        <Field
          type='text'
          id='SDI'
          name='SDI'
          className='form-control form-control-lg form-control-solid'
        />

          <div className='text-danger mt-2'>
            <ErrorMessage name='SDI' />
          </div>
              
      </div>

      {/* PEC */}
      <div className='fv-row mb-10'>
        <label htmlFor='PEC' className='form-label required'>
          PEC
        </label>
        <Field
          type='text'
          id='PEC'
          name='PEC'
          className='form-control form-control-lg form-control-solid'
        />          
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='PEC' />
          </div>          
        
      </div>

      {/* Email */}
      <div className='fv-row mb-10'>
        <label htmlFor='Email' className='form-label required'>
          Email
        </label>
        <Field
          type='text'
          id='Email'
          name='Email'
          className='form-control form-control-lg form-control-solid'
        />      
        
          <div className='text-danger mt-2'>
            <ErrorMessage name='Email' />
          </div>          
        
      </div>

      {/* Intermediario */}
      <div className='fv-row mb-10'>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          È tramite intermediario?
        </label>

        <div className='row mb-2' data-kt-buttons='true'>

          {/*Radio button 1*/}
          <div className='col'>
            <Field name='IntermediarioRadio'>
              {({ field }: { field: any }) => (
                <input
                  type='radio'
                  id='kt_intermediario_select_1'
                  {...field}
                  value='Si'
                  checked={field.value === 'Si'}
                  onChange={(event) => {
                    field.onChange(event); // Prima chiami l'onChange di Formik
                    handleIntermediaresChange(event); // Poi chiami il tuo gestore personalizzato
                  }}
                  className='btn-check'
                />
              )}
            </Field>
            <label
              htmlFor='kt_intermediario_select_1'
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
            >
              Si
            </label>
          </div>

          {/*Radio button 2*/}
          <div className='col'>
            <Field name='IntermediarioRadio'>
              {({ field }: { field: any }) => (
                <input
                  type='radio'
                  id='kt_intermediario_select_2'
                  {...field}
                  value='No'
                  checked={field.value === 'No'}
                  onChange={(event) => {
                    field.onChange(event); // Prima chiami l'onChange di Formik
                    handleIntermediaresChange(event); // Poi chiami il tuo gestore personalizzato
                  }}
                  className='btn-check'
                />
              )}
            </Field>
            <label
              htmlFor='kt_intermediario_select_2'
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
            >
              No
            </label>
          </div>
        </div>
        </div>
        <div className='fv-row mb-10'>
        {/* Selezione intermedario */}
        {isIntermediary &&
          <div className='fv-row mt-3'>
            <label className='d-flex align-items-center form-label mb-5 required'>
              Seleziona intermediario
            </label>
            <Select
              className='form-select-solid form-select-lg fw-bold'
              options={intermediariesList.map((intermediario) => ({ value: intermediario.ID_Piano_abbonamento, label: intermediario.Nome }))}
              isSearchable={false}
              onChange={(option) => {
                setSelectedIntermediary(option ? option.label : '');
                setFieldValue('Intermediario', option ? option.label : '');
              }}
              placeholder= "Seleziona un intermediario"
            />
          </div>
        }
        </div>
      </div>

    </div>
);
};

export { Step1 };
