import * as Yup from 'yup'
import {validationConfig6} from '../../../../validationConfig';

export interface IRenewOrderSameConditionsSchemas {
    RenewalStartDate: Date | null;
}

export const RenewOrderSameConditionsSchemas = Yup.object().shape({
    RenewalStartDate: validationConfig6.RenewalStartDate.required
    ? Yup.date()
        .nullable()
        .required('Data entrata ordine obbligatoria')
        .max(new Date(), 'La data inserita non può essere successiva a quella odierna')
        .label('RenewalStartDate')
    : Yup.string().nullable().label('RenewalStartDate'),
});

export const inits: IRenewOrderSameConditionsSchemas = {
  RenewalStartDate: null,
}
