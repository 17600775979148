import { useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../helpers'
import { renewOrderSameConditions } from '../../../../api/ordersApi'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { RenewOrderSameConditionsSchemas, inits } from './RenewOrderSameConditionsChecker'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
  numGoggles: number | null
  numGogglesAssigned: number | null
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const RenewOrderSameConditions = ({ show, handleClose, recordId }: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleRenewOrder = async (values: any, actions: any) => {
    try {
      await renewOrderSameConditions(recordId, values.RenewalStartDate, values.RenewalPaymentMethod, values.RenewalPaymentMode);
      setShowConfirmModal(true);
      handleClose();
      actions.resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
      <Modal
        id="kt_modal_order_delivered"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={show}
        onHide={handleClose}
      >
        <Formik
          initialValues={inits}
          validationSchema={RenewOrderSameConditionsSchemas}
          onSubmit={handleRenewOrder}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h2>Rinnovo alle stesse condizioni</h2>
                <div className="btn btn-sm btn-icon btn-active-color-scaduto" onClick={handleClose}>
                  <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
                </div>
              </div>

              <div className="modal-body">
                {/* Data partenza rinnovo */}
                <div className="fv-row mb-10">
                  <label htmlFor="RenewalStartDate" className="form-label required">
                    Data partenza rinnovo:
                  </label>
                  <Field
                    type="date"
                    id="RenewalStartDate"
                    name="RenewalStartDate"
                    className="form-control form-control-lg form-control-solid"
                  />
                  <div className="text-danger mt-2">
                    <ErrorMessage name="RenewalStartDate" />
                  </div>
                </div>

                {/* Metodo di pagamento */}
                <div className="fv-row mb-10">
                  <label className="form-label required">Metodo di pagamento</label>
                  <div className="row mb-2" data-kt-buttons="true">
                    {["Carta", "Paypal", "Bonifico", "Ri.Ba", "SDD", "RID"].map((method) => (
                      <div className="col" key={method}>
                        <Field
                          type="radio"
                          id={method}
                          name="RenewalPaymentMethod"
                          value={method}
                          className="btn-check"
                        />
                        <label htmlFor={method} className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                          <span className="fw-bolder fs-3">{method}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="text-danger mt-2">
                    <ErrorMessage name="RenewalPaymentMethod" />
                  </div>
                </div>

                {/* Modalità di pagamento */}
                <div className="fv-row mb-10">
                  <label className="form-label required">Modalità di pagamento</label>
                  <div className="row mb-2" data-kt-buttons="true">
                    {["Unica soluzione", "Mensile"].map((mode) => (
                      <div className="col" key={mode}>
                        <Field
                          type="radio"
                          id={mode}
                          name="RenewalPaymentMode"
                          value={mode}
                          className="btn-check"
                        />
                        <label htmlFor={mode} className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                          <span className="fw-bolder fs-3">{mode}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="text-danger mt-2">
                    <ErrorMessage name="RenewalPaymentMode" />
                  </div>
                </div>

                <button type="submit" className="btn btn-lg btn-light-scaduto me-3">
                  Invio
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        id="kt_modal_confirm_assign"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={showConfirmModal}
        onHide={closeConfirmModal}
      >
        <div className="modal-header">
          <h2>Rinnovo ordine alle stesse condizioni</h2>
          <div className="btn btn-sm btn-icon btn-active-color-scaduto" onClick={closeConfirmModal}>
            <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
          </div>
        </div>
        <div className="modal-body">
          <p>L'ordine è stato rinnovato con successo!</p>
        </div>
        <div className="modal-footer">
          <button className="btn btn-lg btn-light-scaduto me-3" onClick={closeConfirmModal}>
            Chiudi
          </button>
        </div>
      </Modal>
    </>,
    modalsRoot
  );
};

export { RenewOrderSameConditions };