import axios from '../config/axiosConfig';

/* Creazione nuovo prossima uscita */
export const createNextRelease = async (nextReleasedata) => {
  try {
    const response = await axios.post('/api/simulationsUpcoming/create', nextReleasedata);
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error creating next releasing:', error);
    throw error;
  }
};

