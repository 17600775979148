import React, { useEffect, useState } from 'react'
import axios from '../../../../config/axiosConfig'
import Select from 'react-select'

export interface IClient {
  id: number
  Ragione_sociale: string
  P_IVA: string
  CF: string
  SDI: string
  PEC: string
  Email: string
}

interface IProps {
  onSelect: (client: IClient, ragioneSociale: string) => void;
}

interface IOption {
  value: number;
  label: string;
}

const ExistingClientSelector: React.FC<IProps> = ({ onSelect }) => {
  const [clienti, setClienti] = useState<IClient[]>([]);
  const [selectedClienti, setSelectedClienti] = useState<IOption | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post<IClient[]>('/api/clients/all/inactive');
		    const orderedClients = response.data.sort((a, b) => a.Ragione_sociale.localeCompare(b.Ragione_sociale));
        setClienti(orderedClients);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="modal-body">
      <Select
        className='form-select-solid form-select-lg fw-bold'
        value={selectedClienti}
        placeholder="Scegli"
        options={clienti.map((cliente, index) => ({ value: index, label: cliente.Ragione_sociale }))}
        onChange={(selectedOption: IOption | null) => {
          if (selectedOption) {
            const selectedClient = clienti[selectedOption.value];
            setSelectedClienti(selectedOption);
            onSelect(selectedClient, selectedClient.Ragione_sociale);
          } else {
            setSelectedClienti(null);
            // Gestisci il caso in cui non viene selezionata nessuna opzione
          }
        }}
      />
    </div>
  );
};

export default ExistingClientSelector;
