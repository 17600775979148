export const verificaCorrispondenzaSeriali = (assignedGoggles, selectedGoggleModelQuantities, goggleModelList) => {

  if (!assignedGoggles) {
    return false; // o un'altra logica appropriata
  }

  const conteggioPerModelloDaSeriali = {
    'Quest 2': 0,
    'Quest 3': 0,
    'Quest 3s': 0,
    // Aggiungi altri modelli quando usciranno
  };

  // Calcola le quantità per modello dai seriali selezionati
  assignedGoggles.forEach(serial => {
    if (serial.value.startsWith('1WMHH')) {
      conteggioPerModelloDaSeriali['Quest 2'] += 1;
    } else if (serial.value.startsWith('2G0YC')) {
      conteggioPerModelloDaSeriali['Quest 3'] += 1;
    } else if (serial.value.startsWith('340YC')) {
      conteggioPerModelloDaSeriali['Quest 3s'] += 1;
    }
    // Aggiungi ulteriori condizioni se ci sono altri modelli
  });

  // Confronta con le quantità selezionate
  for (const [modelloId, quantita] of Object.entries(selectedGoggleModelQuantities)) {
    const modello = goggleModelList.find(m => m.ID_Visore === parseInt(modelloId));
    if (modello && conteggioPerModelloDaSeriali[modello.Modello] !== quantita) {
      return false; // Le quantità non corrispondono
    }
  }
  return true; // Tutte le quantità corrispondono
};