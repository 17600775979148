/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { listUsers } from '../../../../api/usersApi';
import { UsersListSearchComponent } from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent';
import { UsersListPagination } from '../../../../app/modules/apps/user-management/users-list/components/pagination/UsersListPagination';
import dayjs from 'dayjs';
import 'dayjs/locale/it';

type Props = {
  className: string;
};

const GestioneUtentiWidget: React.FC<Props> = ({ className }) => {
  const [user, setUsers] = useState<
    { Nome: string; Ruolo: string; Data_ultimo_accesso: string }[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Stato per il termine di ricerca
  const [currentPage, setCurrentPage] = useState(1); // Stato per la pagina corrente
  const itemsPerPage = 10; // Numero di elementi per pagina

  // Filtra gli utenti in base al termine di ricerca
  const filteredUsers = useMemo(() => {
    if (searchTerm.trim() === '') {
      return user;
    }
    const lowerCasedSearchTerm = searchTerm.toLowerCase();
    return user.filter((u) =>
      Object.values(u).some((val) =>
        val && typeof val === 'string' && val.toLowerCase().includes(lowerCasedSearchTerm)
      )
    );
  }, [searchTerm, user]);

  // Calcolo degli utenti per la paginazione
  const paginatedUsers = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredUsers.slice(start, end);
  }, [filteredUsers, currentPage, itemsPerPage]);

  // Caricamento dati utenti
  const fetchUsers = async () => {
    try {
      const response = await listUsers();
      const formattedUsers = response.map((user: any) => ({
        ...user,
        Data_ultimo_accesso: dayjs(user.Data_ultimo_accesso).format('DD-MM-YYYY HH:mm:ss'),
      }));
      setUsers(formattedUsers);
    } catch (error) {
      console.error('Errore nel recuperare gli utenti:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Barra di ricerca */}
      <div className='card-header border-0 pt-6'>
        <UsersListSearchComponent setParentSearchTerm={setSearchTerm} />
      </div>
      {/* end::Barra di ricerca */}

      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Gestione utenti</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Nuovo utente
          </a>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>UTENTE</th>
                <th className='min-w-140px'>RUOLO</th>
                <th className='min-w-120px'>ULTIMO ACCESSO</th>
                <th className='min-w-100px text-end'>AZIONI</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {paginatedUsers.map((user, index) => (
                <tr key={index}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {user.Nome}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                  <span
                    className={`badge fw-semibold fs-7 ${
                      user.Ruolo === 'dipendente' ? 'badge-primary' :
                      user.Ruolo === 'admin' ? 'badge-info' :
                      user.Ruolo === 'socio' ? 'badge-success' : 'badge-secondary'
                    }`}
                  >
                    {user.Ruolo}
                  </span>
                  </td>
                  <td className='text-start'>
                    <span className='text-muted fw-semibold fs-7 text-body'>{user.Data_ultimo_accesso}</span>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen019.svg'
                          className='svg-icon-3'
                        />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* Pagina paginazione */}
        <UsersListPagination
          totalItems={filteredUsers.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
      {/* end::Body */}
    </div>
  );
};

export { GestioneUtentiWidget };
