import axios from '../config/axiosConfig';

/* Creazione nuova prova gratuita */
export const createFreeTrial = async (freeTrialData) => {
  try {
    const response = await axios.post('/api/orders/createFreeTrial', freeTrialData);
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error creating free trial:', error);
    throw error;
  }
};

/* Creazione nuovo ordine */
export const createOrder = async (orderData) => {
  try {
    const response = await axios.post('/api/orders/create', orderData);
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error creating order:', error);
    throw error;
  }
};

/* Modifica ordine */
export const editOrder = async (orderId, orderData) => {
  try {
    const response = await axios.put(`/api/orders/edit/${orderId}`, { orderData });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error editing order:', error);
    throw error;
  }
};

/* Elimina ordine */
export const deleteOrder = async (orderId, orderData) => {
  try {
    const response = await axios.put(`/api/orders/delete/${orderId}`, { orderData });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error deleting order:', error);
    throw error;
  }
};

/* Importo medio speso da un cliente */
export const averageAmountSpent = async () => {
  try {
    const response = await axios.post('/api/stats/averageamountspent');
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching average amount spent:', error);
    throw error;
  }
};

/* Stato ordini */
export const statusOrders = async () => {
  try {
    const response = await axios.post('/api/orders/status/processing');
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching status orders:', error);
    throw error;
  }
};

/* Stato prove gratuite*/
export const statusFreeTrials = async () => {
  try {
    const response = await axios.post('/api/orders/status/freetrials');
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching status free trials:', error);
    throw error;
  }
};

/* Dettagli ordine */
export const orderDetails = async (id) => {
  try {
    const response = await axios.get(`/api/orders/status/processing/details/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching status orders: details', error);
    throw error;
  }
};

/* Dettagli ordine per modificarlo */
export const orderDetailsToEdit = async (id) => {
  try {
    const response = await axios.get(`/api/orders/status/processing/detailsToEdit/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching status orders: details to edit', error);
    throw error;
  }
};

/* Elenco dei visori disponibili disponibili per essere assegnati ad una prova gratuita */
export const listGooglesFreeTrial = async () => {
  try {
    const response = await axios.post('/api/goggles/listFreeTrial');
    return response.data;
  } catch (error) {
    console.error('Error fetching goggles: ', error);
    throw error;
  }
};

/* Elenco dei visori disponibili in stock disponibili per essere assegnati ad un determinato ordine */
export const listGoogles = async (orderId) => {
  try {
    const response = await axios.post('/api/goggles/list', {orderId});
    return response.data;
  } catch (error) {
    console.error('Error fetching goggles: ', error);
    throw error;
  }
};

/* Elenco dei visori assegnati ad un determinato ordine */
export const listGooglesAssigned = async (orderId) => {
  try {
    const response = await axios.post('/api/goggles/listAssigned', {orderId});
    return response.data;
  } catch (error) {
    console.error('Error fetching goggles assigned: ', error);
    throw error;
  }
};

/* Elenco delle simulazioni a catalogo disponibili per essere assegnate ad un ordine */
export const listSimulations = async () => {
  try {
    const response = await axios.post('/api/simulations/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching simulations: ', error);
    throw error;
  }
};

/* Elenco dei piani di abbonamento a catalogo disponibili per essere assegnati ad un ordine */
export const listSubscriptionPlans = async () => {
  try {
    const response = await axios.post('/api/subscriptionPlans/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching subscriptions plans: ', error);
    throw error;
  }
};

/* Elenco delle lunghezze dei piani d'abbonamento a catalogo disponibili per essere assegnati ad un ordine */
export const listSubscriptionLengths = async () => {
  try {
    const response = await axios.post('/api/subscriptionLengths/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching subscriptions length: ', error);
    throw error;
  }
};

/* Elenco delle opzioni aggiuntive disponibili disponibili a catalogo per essere assegnate ad un ordine */
export const listAdditionalOptions = async () => {
  try {
    const response = await axios.post('/api/additionalOption/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching additional options: ', error);
    throw error;
  }
};

/* Elenco dei modelli di visori disponibili a catalogo per essere noleggiati/venduti */
export const listGoggleModels = async () => {
  try {
    const response = await axios.post('/api/goggleModel/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching goggle models: ', error);
    throw error;
  }
};

/* Elenco degli intermediari disponibili */
export const listIntermediaries = async () => {
  try {
    const response = await axios.post('/api/intermediaries/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching intermediaries: ', error);
    throw error;
  }
};

/* Assegna un visore a una prova gratuita */
export const assignGoggleToFreeTrial = async (orderId, goggleId) => {
  try {
    const response = await axios.put(`/api/goggles/assignGoggleToFreeTrial/${orderId}`, { goggleId });
    return response.data;
  } catch (error) {
    console.error('Error assigning goggles: ', error);
    throw error;
  }
};

/* Assegna un visore a un ordine */
export const assignGoggles = async (orderId, goggleId) => {
  try {
    const response = await axios.put(`/api/goggles/assignGoggles/${orderId}`, { goggleId });
    return response.data;
  } catch (error) {
    console.error('Error assigning goggles: ', error);
    throw error;
  }
};

/* Inserisci la data di partenza e arrivo stimata di un ordine */
export const insertShipment = async (orderId, courier, cost, estimatedDeliveryDate, estimatedArrivalDate) => {
  try {
    const response = await axios.post(`/api/orders/insertShipment/${orderId}`, { courier, cost, estimatedDeliveryDate, estimatedArrivalDate });
    return response.data;
  } catch (error) {
    console.error('Error inserting shipment: ', error);
    throw error;
  }
};

/* Aggiorna la data di partenza effettiva di una prova gratuita */
export const updateShipmentFreeTrial = async (orderId, effectiveDeliveryDate) => {
  try {
    const response = await axios.put(`/api/orders/updateShipmentFreeTrial/${orderId}`, { effectiveDeliveryDate });
    return response.data;
  } catch (error) {
    console.error('Error updating shipment: ', error);
    throw error;
  }
};

/* Aggiorna la data di partenza effettiva di un ordine */
export const updateShipment = async (orderId, effectiveDeliveryDate) => {
  try {
    const response = await axios.put(`/api/orders/updateShipment/${orderId}`, { effectiveDeliveryDate });
    return response.data;
  } catch (error) {
    console.error('Error updating shipment: ', error);
    throw error;
  }
};

/* Aggiorna la data di arrivo effettivo di un ordine */
export const orderDelivered = async (orderId, effectiveArrivalDate) => {
  try {
    const response = await axios.put(`/api/orders/orderDelivered/${orderId}`, { effectiveArrivalDate });
    return response.data;
  } catch (error) {
    console.error('Error updating shipment 2: ', error);
    throw error;
  }
};

/* Rinnova l'ordine già esistente alle stesse condizioni, inserendo un nuovo ordine uguale alla tabella ordini */
export const renewOrderSameConditions = async (orderId, renewalStartDate, RenewalPaymentMethod, RenewalPaymentMode) => {
  try {
    const response = await axios.post(`/api/orders/orderExpired/renewOrderSameConditions/${orderId}`, { renewalStartDate, RenewalPaymentMethod, RenewalPaymentMode });
    return response.data;
  } catch (error) {
    console.error('Error renewing order 2: ', error);
    throw error;
  }
};

/* Rinnova l'ordine già esistente a condizioni diverse, inserendo un nuovo ordine con le modifiche alle condizioni inserite */
export const renewOrderDifferentConditions = async (orderId, orderData) => {
  try {
    const response = await axios.post(`/api/orders/orderExpired/renewOrderDifferentConditions/${orderId}`, { orderData });
    return response;
  } catch (error) {
    console.error('Error renewing order 2: ', error);
    throw error;
  }
};

/* Termina il contratto, non rinnovandolo */
export const endContract = async (orderId) => {
  try {
    const response = await axios.put(`/api/orders/orderExpired/endContract/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error ending contract 2: ', error);
    throw error;
  }
};

/* Si segnano i visori dell'ordine ritornati alla base */
export const returnGoggles = async (orderId, id_visore_returned, dataReturned) => {
  try {
    const response = await axios.put(`/api/orders/orderExpired/returnGoggles/${orderId}`, { id_visore_returned, dataReturned });
    return response.data;
  } catch (error) {
    console.error('Error ending contract 2: ', error);
    throw error;
  }
};