import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikHelpers} from 'formik'
import {ICreateSubscriptionPlan, createSubscriptionPlanSchemas, inits} from './CreateSubscriptionPlanChecker'
import {createSubscriptionPlan} from '../../../../api/subscriptionPlanApi'
import {Modal} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';


const InserisciPianoAbbonamento: React.FC = ({ }) => {
  const [initValues] = useState<ICreateSubscriptionPlan>(inits)
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('');


  const submitForm = async (values: ICreateSubscriptionPlan, actions: FormikHelpers<ICreateSubscriptionPlan>) => {
    try {
      const response = await createSubscriptionPlan(values)
      if (response.status === 201) {
        setModalMessage(response.data);
      }
      setShowModal(true);

      // Reset dei campi del form
      actions.resetForm();

      } catch (error: any) {
        if (error.response) {
          // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
          if (error.response.status === 400) {
            setModalMessage(error.response.data); //esiste già un visore con questi seriali
          }
          else if (error.response.status === 419) {
            setModalMessage(error.response.data); //almeno uno dei due seriali deve essere inserito
          }
        } else if (error.request) {
          // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
          setModalMessage(error.response.data);
        } else {
          // Qualcosa è andato storto nella creazione della richiesta
          setModalMessage(error.response.data);
        }
        setShowModal(true);
      }
      actions.resetForm()
  }

  const closeModal = () => setShowModal(false)

  return (
    <div className="d-flex justify-content-center">
      <div className='d-flex flex-column bg-body rounded w-100' style={{ maxWidth: '700px' }}>
        <Formik initialValues={initValues} validationSchema={createSubscriptionPlanSchemas} onSubmit={submitForm}>
          {({ setFieldValue }) => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>

              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder text-dark'>Piano d'abbonamento</h2>
                <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
              </div>

              {/* Seriale scatola */}
              <div className='fv-row mb-10'>
                <label htmlFor='Nome' className='form-label required'>
                  Nome
                </label>
                <Field
                  type='text'
                  id='Nome'
                  name='Nome'
                  className='form-control form-control-lg form-control-solid'
                />
          
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Nome' />
                  </div>
              </div>

              {/* Seriale visore */}
              <div className='fv-row mb-10'>
                <label htmlFor='Numero_simulazioni' className='form-label required'>
                  Numero di simulazioni
                </label>
                <Field
                  type='number'
                  id='Numero_simulazioni'
                  name='Numero_simulazioni'
                  className='form-control form-control-lg form-control-solid'
                />
          
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Numero_simulazioni' />
                  </div>
              </div>
              
              <div className='d-flex flex-stack pt-10'>
                <button type='submit' className='btn btn-lg btn-primary me-3'>
                  Invia
                  <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Piano abbonamento inserito correttamente</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={closeModal}>
              Chiudi
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export { InserisciPianoAbbonamento }
