import * as Yup from 'yup'
import {validationConfig5} from '../../../../validationConfig';

export interface ICreateNextRelease {
    Nome: string
    Periodo: string
}

export const createNextReleaseSchemas = Yup.object().shape({
    Nome: validationConfig5.Nome.required
    ? Yup.string().required('Nome obbligatorio').label('Nome')
    : Yup.string().label('Nome'),
    Periodo: validationConfig5.Periodo.required
    ? Yup.string().required('Periodo obbligatorio').label('Periodo')
    : Yup.string().label('Periodo'),
});

export const inits: ICreateNextRelease = {
    Nome: '',
    Periodo: '',
}
