import axios from '../config/axiosConfig';

/* Elenco delle simulazioni che usciranno in futuro */
export const listUpcomingReleases = async () => {
  try {
    const response = await axios.post('/api/simulationsUpcoming/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching upcoming releases: ', error);
    throw error;
  }
};

/* Elenco delle simulazioni che usciranno in futuro */
export const listSimulations = async () => {
  try {
    const response = await axios.post('/api/simulations/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching simulations: ', error);
    throw error;
  }
};
