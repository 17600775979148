/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CustomWidget2_2} from '../../../_metronic/partials/widgets'

export function LibroMastro() {
  return (
    <>	
      <div className='col-xl-12'>
        <CustomWidget2_2 className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </>
  )
}
