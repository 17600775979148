/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {
  CustomWidget5,
} from '../../../_metronic/partials/widgets/customs/CustomWidget5'

const DashboardPage: FC = () => (
  <>
    <CustomWidget5 />
  </>
)

const DashboardWrapper: FC = () => {
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
