import React, { useState, useMemo, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { listUpcomingReleases, listSimulations } from '../../../../api/homeApi';
import './CustomWidget5.css';
import dayjs from 'dayjs';
import 'dayjs/locale/it';

// Definisci il tipo di dati che ti aspetti dall'API
interface UpcomingRelease {
  Nome: string;
  Periodo: string;
}

// Componente Card
const InfoCard: React.FC<{ title: string; subtitle: string; items: { label: string; value: string }[] }> = ({
  title,
  subtitle,
  items,
}) => {
  return (
    <div className='card mb-4' style={{ backgroundColor: '#ffffff' }}>
      <div className='card-body'>
        <div className='text-dark fw-bold fs-2 mb-2 mt-5 custom-text-title margine-titoli-box'>{title}</div>
        <div className='text-muted fs-6 mb-4 custom-subtitle'>{subtitle}</div>
        {items.map((item, index) => (
          <div key={index} className='d-flex justify-content-between py-2'>
            <span className='text-muted custom-text'>{item.label}</span>
            <span className='text-muted fw-bold custom-text'>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Componente per la tabella Simulazioni rilasciate con paginazione
const SimulationsTable: React.FC = () => {
  const [simulations, setSimulations] = useState<{ Nome: string; Data_ultimo_rilascio: string }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 3;

  const fetchSimulation = async () => {
    try {
      const response = await listSimulations();
      for (let i = 0; i < response.length; i++) {
        let releaseDateFormatted = dayjs(response[i].Data_ultimo_rilascio);
        response[i].Data_ultimo_rilascio = releaseDateFormatted.format('DD-MM-YYYY');
      }
      setSimulations(response);
    } catch (error) {
      console.error('Errore nel recuperare le simulazioni:', error);
    }
  };

  useEffect(() => {
    fetchSimulation();
  }, []);

  const paginate = (data: any[], pageNumber: number, itemsPerPage: number) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const filteredSimulations = useMemo(() => {
    return simulations.filter(simulation =>
      simulation && simulation.Nome && simulation.Nome.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, simulations]);

  const totalPages = Math.ceil(filteredSimulations.length / itemsPerPage);
  const currentData = paginate(filteredSimulations, currentPage, itemsPerPage);

  const emptyRows = Array.from({ length: itemsPerPage - currentData.length });

  return (
    <div className='card' style={{ backgroundColor: '#ffffff' }}>
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1 custom-text-title'>Simulazioni rilasciate</span>
        </h3>
        <div className='d-flex align-items-center position-relative my-1'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-200px ps-14'
            placeholder='  Cerca'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-3' style={{ backgroundColor: '#ffffff' }}>
            <tbody>
              {currentData.map((simulation, index) => (
                <tr key={index}>
                  <td className='custom-table-text text-muted'>{simulation.Nome}</td>
                  <td className='text-end'>
                    <span className='text-dark d-block fs-7 custom-table-text text-muted'>{simulation.Data_ultimo_rilascio}</span>
                  </td>
                </tr>
              ))}
              {emptyRows.map((_, index) => (
                <tr key={`empty-${index}`}>
                  <td className='custom-table-text text-muted'>&nbsp;</td>
                  <td className='text-end'>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          totalItems={filteredSimulations.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          prevIcon="/media/icons/duotune/arrows/arr022.svg"
          nextIcon="/media/icons/duotune/arrows/arr023.svg"
        />
      </div>
    </div>
  );
};

// Componente per le "Prossime Uscite" con paginazione
const UpcomingReleases: React.FC<{ upcomingReleases: UpcomingRelease[] }> = ({ upcomingReleases }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 3;

  const paginate = (data: any[], pageNumber: number, itemsPerPage: number) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const filteredReleases = useMemo(() => {
    return upcomingReleases.filter(release =>
      release && release.Nome && release.Nome.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, upcomingReleases]);

  const totalPages = Math.ceil(filteredReleases.length / itemsPerPage);
  const currentData = paginate(filteredReleases, currentPage, itemsPerPage);

  const emptyRows = Array.from({ length: itemsPerPage - currentData.length });

  return (
    <div className='card' style={{ backgroundColor: '#ffffff' }}>
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1 custom-text-title'>Prossime uscite</span>
        </h3>
        <div className='d-flex align-items-center position-relative my-1'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-200px ps-14'
            placeholder='  Cerca'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-3' style={{ backgroundColor: '#ffffff' }}>
            <tbody>
              {currentData.map((release, index) => (
                <tr key={index}>
                  <td className='custom-table-text text-muted'>{release.Nome}</td>
                  <td className='custom-table-text text-muted text-end'>{release.Periodo}</td>
                </tr>
              ))}
              {emptyRows.map((_, index) => (
                <tr key={`empty-${index}`}>
                  <td className='custom-table-text text-muted'>&nbsp;</td>
                  <td className='custom-table-text text-muted text-end'>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          totalItems={filteredReleases.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          prevIcon="/media/icons/duotune/arrows/arr022.svg"
          nextIcon="/media/icons/duotune/arrows/arr023.svg"
        />
      </div>
    </div>
  );
};

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  prevIcon?: string;
  nextIcon?: string;
}

const Pagination: React.FC<PaginationProps> = ({ totalItems, itemsPerPage, currentPage, onPageChange, prevIcon, nextIcon }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className="pagination-container d-flex justify-content-center align-items-center mt-3 no-margine2">
      <button
        className="btn btn-outline-secondary me-2"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {prevIcon ? <img src={prevIcon} alt="Prev Icon" width="16" height="16" /> : 'Precedente'}
      </button>
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={index}
          className={`btn btn-outline-secondary ${index + 1 === currentPage ? 'active' : ''} mx-1`}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        className="btn btn-outline-secondary ms-2"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {nextIcon ? <img src={nextIcon} alt="Next Icon" width="16" height="16" /> : 'Successivo'}
      </button>
    </div>
  );
};

// Componente principale CustomWidget5
const CustomWidget5: React.FC = () => {

  const cardData = [
    {
      title: 'TD Synnex',
      subtitle: 'Prezzo IVA esclusa',
      items: [
        { label: 'Meta Quest 3', value: '€480' },
        { label: 'Meta Quest 3s', value: '€300' },
      ],
    },
    {
      title: 'ManageXR',
      subtitle: 'Costo licenza al mese IVA esclusa',
      items: [
        { label: 'Mensile', value: '€6,50' },
        { label: 'Annuale', value: '€5,75' },
      ],
    },
    {
      title: 'Accessori quest',
      subtitle: 'Prezzo IVA esclusa',
      items: [
        { label: 'Cinturino elite', value: '€20' },
        { label: 'Cavo mirroring 5 metri', value: '€18' },
      ],
    },
  ];  

  const [upcomingReleases, setUpcomingReleases] = useState<UpcomingRelease[]>([]); // Stato per le prossime uscite

  // Funzione per fare la chiamata al backend
  const fetchUpcomingReleases = async () => {
    try {
      let response = await listUpcomingReleases();
      console.log("rispods", response)
      setUpcomingReleases(response); 
    } catch (error) {
      console.error('Errore nel recuperare le prossime uscite:', error);
    }
  };

  // Effettua la chiamata al backend una volta all'inizializzazione del componente
  useEffect(() => {
    fetchUpcomingReleases();
  }, []);

  return (
    <div className="container-fluid" style={{ padding: '0px' }}>
      {/* Prima riga: Card informative */}
      <div className="row">
        {cardData.map((card, index) => (
          <div key={index} className="col-xl-4 col-lg-6">
            <InfoCard title={card.title} subtitle={card.subtitle} items={card.items} />
          </div>
        ))}
      </div>

      {/* Seconda riga: Tabella e prossime uscite */}
      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <SimulationsTable />
        </div>
        <div className="col-xl-6 col-lg-12">
          <UpcomingReleases upcomingReleases={upcomingReleases} />
        </div>
      </div>
    </div>
  );
};

export { CustomWidget5 };
