/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from "react"
import {createPortal} from 'react-dom'
import Select from 'react-select'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {returnGoggles, listGooglesAssigned} from '../../../../api/ordersApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
  numGoggles: number | null
  numGogglesAssigned: number | null
};

type OptionType = {
  value: number;
  label: string;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const ReturnGoggles = ({ show, handleClose, recordId, numGoggles, numGogglesAssigned }: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [gogglesListAssigned, setGogglesListAssigned] = useState<any[]>([]);
  const [selectedGoggleIds, setSelectedGoggleIds] = useState<number[]>([]);

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [gogglesReturned, setGogglesReturned] = useState<number | null>(null);

  const [returnedGoggleDate, setReturnedGoggleDate] = useState<string | null>(null);
  
  const handleRadioChange = (value: string) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    if (show) {
      fetchGogglesListAssigned();
    }
  }, [show]);

  const fetchGogglesListAssigned = async () => {
    try {
      const response = await listGooglesAssigned(recordId);
      setGogglesListAssigned(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReturnGoggles = async () => {
      try {
        for(let i = 0; i < selectedGoggleIds.length; i++) {
          await returnGoggles(recordId, selectedGoggleIds[i], returnedGoggleDate);
        }

        // Resetta i visori selezionati dopo l'assegnazione
        setSelectedGoggleIds([]);

        handleClose();
        setShowConfirmModal(true);
      } catch (error) {
        console.error(error);
      }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_order_delivered"
      tabIndex={-1}
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Rientro visori</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>
      <div className="modal-body">
      <label className='form-label required'>Visori tornati: </label>
        <Select
              isMulti
              className='form-select-solid form-select-lg fw-bold'
              value={selectedGoggleIds.map((id) => ({ value: id, label: gogglesListAssigned.find((goggle) => goggle.ID_Visore_in_stock === id)?.Seriale_visore }))}
              placeholder="Scegli visori"
              options={gogglesListAssigned.map((goggle) => ({ value: goggle.ID_Visore_in_stock, label: goggle.Seriale_visore }))}
              onChange={(selectedOptions) => {
                if (selectedOptions && Array.isArray(selectedOptions)) {
                  setSelectedGoggleIds(selectedOptions.map((option) => option.value));
                } else {
                  setSelectedGoggleIds([]);
                }
              }}
          />
        <br></br>
        <label htmlFor="effective-departure-date" className='form-label required'>Data rientro visori: </label>
          <input
            type="date"
            id="effective-departure-date"
            value={returnedGoggleDate || ''}
            onChange={event => setReturnedGoggleDate(event.target.value)}
            className='form-control form-control-lg form-control-solid'
          />
        <br></br><br></br>
        <button className='btn btn-lg btn-light-inattivo me-3' onClick={handleReturnGoggles}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Rientro visori</h2>
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>Il ritorno dei visori è stato segnato con successo</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { ReturnGoggles };