import { useState, useEffect, useRef } from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {Formik, Form, FormikHelpers} from 'formik'
import {Step1} from '../../../../app/pages/ordini_noleggio-vendita/Modifica_ordine/steps/Step1'
import {Step2_2} from '../../../../app/pages/ordini_noleggio-vendita/Modifica_ordine/steps/Step2_2'
import {Step3} from '../../../../app/pages/ordini_noleggio-vendita/Modifica_ordine/steps/Step3'
import {Step4} from '../../../../app/pages/ordini_noleggio-vendita/Modifica_ordine/steps/Step4'
import {Step5_2} from '../../../../app/pages/ordini_noleggio-vendita/Modifica_ordine/steps/Step5_2'
import {StepperComponent} from '../../../assets/ts/components'
import {ICreateOrder, createOrderSchemas, inits} from '../../../../app/pages/ordini_noleggio-vendita/Modifica_ordine/CreateOrderChecker'
import {editOrder, orderDetailsToEdit} from '../../../../api/ordersApi'
import { listGoggleModels } from '../../../../api/ordersApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const EditOrder1 = ({ show, handleClose, recordId }: Props) => {

  const [goggleModelList, setGoggleModelList] = useState<any[]>([]);

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createOrderSchemas)
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [initValues, setInitValues] = useState<ICreateOrder>(inits);
  const [initValues2, setInitValues2] = useState<any>(null);

  const [selectedGoggleModelQuantities, setSelectedGoggleModelQuantities] = useState<{[key: number]: number}>({});
  const [selectedGoggleModelIds, setSelectedGoggleModelIds] = useState<number[]>([]);


  // Funzione di conversione che trasforma tutti i valori null in stringhe vuote
  function convertNullsToStrings(initValues: any): ICreateOrder {
    const newData: any = {};
    Object.keys(initValues).forEach(key => {
      newData[key] = initValues[key] === null ? '' : initValues[key];
    });
    return newData as ICreateOrder;
  }

  // Funzione per caricare i dati dell'ordine di riferimento dal backend
  const fetchOrderDetails = async (id: number) => {
    setLoading(true);
    try {
      const response = await orderDetailsToEdit(id); 
      // Controllo il valore di COD_Intermediario e imposto IntermediarioRadio
      if (response.currentOrderDetails[0].COD_Intermediario === null) {
        response.currentOrderDetails[0].IntermediarioRadio = 'No';
      } else {
        response.currentOrderDetails[0].IntermediarioRadio = 'Si';
      }
      
      response.currentOrderDetails[0].COD_Intermediario = ""

      if (!(Array.isArray(response.currentOrderDetails[0].Modello_visore))) {
        // Se non è un array, bensì è solo un numero, lo trasformiamo in un array con un solo elemento
        response.currentOrderDetails[0].Modello_visore = [response.currentOrderDetails[0].Modello_visore];
      }
      
      response.currentOrderDetails[0].Modello_visore = response.currentOrderDetails[0].Modello_visore.map((str:string) => Number(str))
      response.currentOrderDetails[0].Quantita_visori = response.currentOrderDetails[0].Modello_visore.length

      setInitValues(convertNullsToStrings(response.currentOrderDetails[0]));
      setInitValues2(response.currentOrderDetails[0]);

    } catch (error) {
      console.error('Errore nel recupero dei dettagli ordine:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show && recordId) {
      fetchOrderDetails(recordId);
    }
  }, [show, recordId]);

  useEffect(() => {
    if (initValues) {
      // Aggiorna lo schema di validazione o qualsiasi altra logica necessaria qui
      setCurrentSchema(createOrderSchemas); // Se necessario
    }
  }, [initValues]);

  const loadStepper = () => {
    try {
      stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
    } catch (err) {
      console.error('Error during StepperComponent.createInstance', err);
      return;
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
  }

  useEffect(() => {
    if (stepperRef.current) {
      loadStepper();
    }
  }, [stepperRef.current])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (stepperRef.current) {
        loadStepper();
      } else {
      }
    }, 1000); // ritardo di 1 secondo
    return () => clearTimeout(timer);
  }, [stepperRef.current]);

  /* Funzione per inviare il form con i dati modificati al backend */
  const submitStep = async (values: ICreateOrder, actions: FormikHelpers<ICreateOrder>) => {

    if (!stepper.current) {
      return;
    }
	
	if (stepper.current.currentStepIndex === 2) {
      // Calcolo della somma delle quantità di tutti i visori selezionati
      const totalQuantity = Object.values(selectedGoggleModelQuantities).reduce((sum, quantity) => sum + quantity, 0);

      if (totalQuantity === 0) {
        // Mostra un messaggio di errore e impedisce di procedere
        alert("Devi selezionare almeno un visore per procedere.");
        return; // Interrompe l'esecuzione ulteriore
      }
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {

      // Fai la chiamata Axios qui per inviare i dati del form
      try {
        //console.log("formik", values)
        const response = await editOrder(recordId, values);
        handleClose();
        if (response.status === 200) {
          // Gestisci la risposta di successo, ad esempio mostrando un messaggio di conferma
          console.log('Ordine modificato con successo');
          setShowModal(true); // Mostra il modal
        } else {
          // Gestisci eventuali errori nella risposta
          console.error('Errore nella modifica dell\'ordine');
        }
      } catch (error) {
        // Gestisci errori di rete o altri errori imprevisti
        console.error('Errore nella chiamata API:', error);
      }
      stepper.current.goto(1);
      actions.resetForm();
    }
  };

  useEffect(() => {
    const fetchModelliVisori = async () => {
      try {
        const response = await listGoggleModels();
        setGoggleModelList(response);

        // Creiamo qui la mappatura dei nomi dei modelli ai loro ID
        const modelNameToId = response.reduce((acc: {[key: string]: number}, modello: {Modello: string, ID_Visore: number}) => {
          acc[modello.Modello] = modello.ID_Visore;
          return acc;
        }, {} as {[key: string]: number});

        if (initValues2 && initValues2.ModelliConteggio) {
          const modelloConteggioParts = initValues2.ModelliConteggio.split(","); // ["1x Quest 2", "2x Quest 3"]
          let totalQuantity = 0;
    
          modelloConteggioParts.forEach((part: string) => {
            const [quantita, ...modelloParts] = part.trim().split(" ");
            const quantitaVisori = parseInt(quantita.replace('x', ''), 10);
            totalQuantity += quantitaVisori;
            const modello = modelloParts.join(' ');
    
            const modelloId = modelNameToId[modello];
    
            if (modelloId !== undefined) {
              setSelectedGoggleModelIds(prev => {
                const newIds = Array(quantitaVisori).fill(modelloId);
                return [...prev, ...newIds];
              });
            }
          });
        }

      } catch (error) {
        console.error('Errore nel recupero dei modelli di visore:', error);
      }
    };
    fetchModelliVisori();
  }, []);

  const closeModal = () => setShowModal(false);

  if (loading) {
    return <div></div>;  // Visualizza un loader o un messaggio di caricamento
  }
  
  return createPortal(
    <>
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
    >
      <div className="modal-header">
        <h2>Modifica ordine</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        {/* Componenti stepper e form da Inserisci_ordine */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        >
          <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
            <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
              <div className='stepper-nav'>
                {/* begin::Step 1*/}
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Anagrafica cliente</h3>
                      <div className='stepper-desc fw-semibold'>Step 1</div>
                    </div>
                  </div>
                  <div className='stepper-line h-40px'></div>
                </div>
                {/* end::Step 1*/}

                {/* begin::Step 2*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Prodotti</h3>
                      <div className='stepper-desc fw-semibold'>Step 2</div>
                    </div>
                  </div>
                  <div className='stepper-line h-40px'></div>
                </div>
                {/* end::Step 2*/}

                {/* begin::Step 3*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Servizi</h3>
                      <div className='stepper-desc fw-semibold'>Step 3</div>
                    </div>
                  </div>
                  <div className='stepper-line h-40px'></div>
                </div>
                {/* end::Step 3*/}

                {/* begin::Step 4*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Indirizzi</h3>
                      <div className='stepper-desc fw-semibold'>Step 4</div>
                    </div>
                  </div>
                  <div className='stepper-line h-40px'></div>
                </div>
                {/* end::Step 4*/}

                {/* begin::Step 5*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>5</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Fatturazione</h3>
                      <div className='stepper-desc fw-semibold'>Step 5</div>
                    </div>
                  </div>
                </div>
                {/* end::Step 5*/}
              </div>
            </div>
          </div>
          <Formik initialValues={initValues} validationSchema={currentSchema} onSubmit={submitStep}>
            
          {() => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              
              <div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2_2 
                         selectedGoggleModelQuantities={selectedGoggleModelQuantities}
                         setSelectedGoggleModelQuantities={setSelectedGoggleModelQuantities}/>
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5_2 />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Indietro
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totatStepsNumber! - 0 && 'Continua'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 0 && 'Invia'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
          </Formik>
        </div>
      </div>
    </Modal>
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Ordine modificato</Modal.Title>
      </Modal.Header>
      <Modal.Body>L'ordine è stato modificato con successo!</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={closeModal}>
          Chiudi
        </button>
      </Modal.Footer>
    </Modal>
    </>,
    modalsRoot
  );
};

export { EditOrder1 };