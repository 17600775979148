import axios from '../config/axiosConfig';

/* Creazione nuovo intermediario */
export const createIntermediaries = async (intermediaryData) => {
  try {
    const response = await axios.post('/api/intermediaries/create', intermediaryData);
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error creating intermediary:', error);
    throw error;
  }
};