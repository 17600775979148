import axios from '../config/axiosConfig';

/* Elenco degli utenti che usciranno in futuro */
export const listUsers = async () => {
  try {
    const response = await axios.post('/api/users/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching users: ', error);
    throw error;
  }
};
