import React, { useState } from 'react'; 
import { InserisciOrdine } from './Inserisci_ordine';
import { InserisciOrdineClientiEsistenti } from './Inserisci_ordine-Esistenti';
import ExistingClientSelector from './ExistingClientSelector';
import { IClient } from './ExistingClientSelector';

export interface IClientType {
  type: 'new' | 'existing';
  client?: IClient;
}

const ClientTypeSelector: React.FC = () => {
  const [clientType, setClientType] = useState<IClientType | null>(null);
  const [showExistingClientSelector, setShowExistingClientSelector] = useState(false);

  const handleExistingClientSelect = (client: IClient) => {
    setClientType({ type: 'existing', client });
    setShowExistingClientSelector(false);
  };

  const handleExistingClientButtonClick = () => {
    setShowExistingClientSelector(!showExistingClientSelector);
  };

  const handleNewClientButtonClick = () => {
    setClientType({type: 'new'});
    setShowExistingClientSelector(false);
  };

  if (clientType === null || showExistingClientSelector) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '67.5vh' }}>
        <div style={{ textAlign: 'center', width: '50%' }} className="button-container">

          <div className='pb-10 pb-lg-15'>
            <h2 className='fw-bolder d-flex align-items-center text-dark' style={{ fontSize: '2.5rem' }}> {/* Aumenta la grandezza del titolo */}
              Scegli il tipo di cliente
            </h2>
          </div>

          <div className='row'>
            <div className='col-lg-6' style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                className='btn btn-outline btn-outline-dashed btn-outline-default mb-10'
                style={{
                  height: '100px',  
                  width: '100%',  
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '17.5px', 
                  fontWeight: 'bold',
                  borderWidth: '2px',  // Aumenta lo spessore del bordo tratteggiato
                }}
                onClick={handleNewClientButtonClick}
              >
                Nuovo
              </button>
            </div>

            <div className='col-lg-6' style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
              <button
                className='btn btn-outline btn-outline-dashed btn-outline-default'
                style={{
                  height: '100px',  
                  width: '100%',  
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '17.5px', 
                  fontWeight: 'bold',
                  borderWidth: '2px',  // Aumenta lo spessore del bordo tratteggiato
                }}
                onClick={handleExistingClientButtonClick}
              >
                Esistente
              </button>

              {showExistingClientSelector && (
                <div style={{ 
                  position: 'absolute', 
                  top: '100%', 
                  right: '2.5%', 
                  marginTop: '10px', 
                  width: '100%'
                }}>
                  <ExistingClientSelector onSelect={handleExistingClientSelect} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return clientType.type === 'new' 
    ? <InserisciOrdine clientType={clientType} /> 
    : clientType.client 
      ? <InserisciOrdineClientiEsistenti client={clientType.client} />
      : null; // O qualsiasi altra cosa si voglia mostrare quando il cliente è undefined
};

export { ClientTypeSelector };
